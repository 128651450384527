.ds-hero {
  clip-path: polygon(0 0, 100% 0, 100% 87%, 0% 100%);
}

.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

.w-checkbox {
  display: block;
  margin-bottom: 5px;
  padding-left: 20px;
}

.w-checkbox::before {
  content: ' ';
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

.w-checkbox::after {
  content: ' ';
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  clear: both;
}

.w-checkbox-input {
  float: left;
  margin-bottom: 0px;
  margin-left: -20px;
  margin-right: 0px;
  margin-top: 4px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-right-color: #ccc;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  border-top-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  border-right-color: #3898ec;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}

h2 {
  margin-top: 0px;
  margin-bottom: 1em;
  font-size: 3.4em;
  line-height: 1.15em;
  font-weight: 600;
}

p {
  margin-bottom: 0.88em;
  font-size: 1.7em;
  line-height: 1.15em;
  font-weight: 600;
}

li {
  margin-bottom: 1em;
  font-size: 1.7em;
  line-height: 1.15em;
  font-weight: 500;
}

.hero-heading {
  position: relative;
  height: 95vh;
  margin-bottom: 0em;
  padding-top: 15.4em;
  padding-bottom: 15.4em;
  padding-left: 8.1vw;
  background-color: transparent;
}

.hero-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 129px;
}

.hero-split {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.subheading {
  max-width: 70vw;
  margin-bottom: 0px;
  font-family: Raleway, sans-serif;
  color: #585858;
  font-size: 2em;
  line-height: 1.2em;
  font-weight: 600;
  letter-spacing: 0.05em;
}

.primary-text {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: #44946c;
  font-size: 2.8em;
  line-height: 1.2em;
  font-weight: 700;
}

.primary-text.section-title {
  margin-top: 0px;
  margin-bottom: 1.645em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 3.4em;
  text-align: center;
}

.signup-form {
  display: none;
  width: 480px;
  max-width: 100%;
  margin-top: 100px;
  margin-bottom: 0px;
}

.sign-up-form-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 35px;
  padding: 3px 6px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 8px;
  border-radius: 40px;
  background-color: #fff;
  box-shadow: 5px 6px 10px 0 rgba(0, 0, 0, 0.25);
}

.signup-field {
  height: 48px;
  margin-bottom: 0px;
  padding: 12px 16px;
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  border-radius: 40px;
  font-size: 14px;
  line-height: 22px;
}

.signup-field:hover {
  border-color: #76879d;
}

.signup-field:focus {
  border-color: transparent;
}

.signup-field::-webkit-input-placeholder {
  color: #8f8f8f;
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
}

.signup-field:-ms-input-placeholder {
  color: #8f8f8f;
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
}

.signup-field::-ms-input-placeholder {
  color: #8f8f8f;
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
}

.signup-field::placeholder {
  color: #8f8f8f;
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
}

.demo-form-submit {
  display: block;
  padding: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  background-color: #44946c;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg');
  background-position: 50% 50%;
  background-size: auto;
  background-repeat: no-repeat;
  background-attachment: fixed;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  text-align: center;
}

.demo-form-submit:hover {
  background-color: #3a4554;
}

.navbar {
  padding: 1em 7.2vw 1em 8.1vw;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f9f9f9;
}

.navbar.primarybg-nav {
  max-height: 15vh;
  background-color: #44946c;
  font-size: 12px;
}

.navbar.white-links {
  height: 15vh;
  background-color: transparent;
}

.heading {
  min-width: 60vw;
  margin-top: 0px;
  margin-bottom: 0.7em;
  font-family: Raleway, sans-serif;
  font-size: 3.4em;
  line-height: 1.5em;
  font-weight: 700;
  text-transform: uppercase;
}

.body {
  background-color: #f9f9f9;
  font-family: Raleway, sans-serif;
  color: #000;
  font-size: 11px;
}

.primary-btn {
  padding: 14px 24px;
  border-radius: 10px;
  background-color: #44946c;
  font-family: Raleway, sans-serif;
  color: #fff;
  font-size: 1.4em;
  line-height: 1.2em;
  font-weight: 800;
  text-decoration: none;
}

.primary-btn.extra-pad-btn {
  padding: 0.7em 2.7em;
  font-size: 2em;
}

.primary-btn.contact-btn {
  margin-left: 2rem;
  padding: 0.75em 1.4em;
  background-color: #44946c;
  font-weight: 600;
}

.primary-btn.demo-sign-btn {
  margin-top: 4.5rem;
  padding: 1.07em 3.5em;
  font-size: 2em;
  font-weight: 800;
}

.primary-btn.footer-submit-btn {
  padding: 0.7em 2.7em;
  border-radius: 5px;
  font-size: 1.28em;
}

.primary-btn.fom-btn {
  padding: 0.7em 1.7em;
  font-family: Lato, sans-serif;
  font-size: 2em;
  font-weight: 700;
}

.primary-btn.fom-btn.right-align {
  float: right;
}

.primary-btn.gt-submit {
  padding: 0.8em 2.5em;
  font-size: 1.7em;
  font-weight: 700;
}

.primary-btn.laep-su-btn {
  padding: 1.1em 2.1em;
  font-size: 2em;
}

.primary-btn.ue-register-btn {
  margin-top: 1.8em;
  padding: 0.8em 2.3em;
  font-size: 1.7em;
}

.primary-btn.event-register {
  margin-top: 5.7em;
  margin-left: 3vw;
  padding: 0.7em 5.7vw;
  font-size: 2em;
}

.primary-btn.sd-submit-btn {
  padding: 1em 3em;
  font-size: 2.1em;
}

.nav-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav-link {
  margin-left: 2.75em;
  padding: 0.9em 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-family: Raleway, sans-serif;
  color: #001d0e;
  font-size: 1.4em;
  line-height: 1.15em;
  font-weight: 600;
}

.nav-link:visited {
  border-bottom: 4px solid #44946c;
}

.nav-link.w--current {
  margin-left: 2.8vw;
  border-bottom: 4px solid #44946c;
  color: #001d0e;
}

.nav-link.white-nav-link {
  color: #fff;
}

.nav-link.primary {
  display: none;
}

.navlogo {
  width: 15vw;
}

.introduction-card {
  max-width: 32vw;
}

.introduction {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 50.5vw;
  padding-right: 7.8vw;
  padding-left: 7.8vw;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url('../images/hero-info-bg.svg');
  background-position: 50% 50%;
  background-size: 100%;
  background-repeat: repeat-x;
  background-attachment: scroll;
}

.white-title {
  margin-bottom: 25px;
  color: #fff;
  font-size: 2.28em;
  line-height: 1.2em;
}

.white-title.intro-title {
  margin-top: 3px;
  font-size: 2.8em;
}

.white-title.cs-title {
  margin-bottom: 1.4em;
  font-size: 2.2em;
}

.white-paragraph {
  max-width: 31.3vw;
  margin-bottom: 0px;
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2.1em;
  line-height: 1.2em;
  font-weight: 400;
}

.white-paragraph.intro-para-2 {
  max-width: 34.5vw;
}

.introduction-icon {
  width: 9.6vw;
  height: 9.6vw;
  margin-left: -3.1vw;
}

.introduction-icon.intro-icon-2 {
  margin-left: -1.4vw;
}

.primary-title {
  color: rgba(68, 148, 108, 0.51);
  font-size: 4.5em;
  line-height: 1.2em;
  font-weight: 800;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.primary-title.category {
  margin-bottom: 0.46em;
  font-size: 6.8em;
}

.primary-title.cs-laep-title {
  margin-top: 1.9em;
}

.our-partners {
  margin-top: 0em;
  padding-top: 11em;
  padding-bottom: 5em;
  background-image: url('../images/partners-bg.svg');
  background-position: 70% 64px;
  background-size: 120% 100%;
  background-repeat: repeat-x;
  font-family: Raleway, sans-serif;
  font-weight: 800;
  text-align: center;
}

.subtitle {
  color: #585858;
  font-size: 2.8em;
  line-height: 1.2em;
  font-weight: 600;
}

.subtitle.product-subtitle {
  margin-top: 32px;
  font-size: 2.2em;
}

.partners-grid {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 9em;
  padding-right: 9vw;
  padding-left: 9vw;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  justify-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  grid-auto-columns: 1fr;
  grid-column-gap: 2.7vw;
  grid-row-gap: 2vw;
  grid-template-areas: ".";
  -ms-grid-columns: 1fr 2.7vw 1fr 2.7vw 1fr 2.7vw 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto 2vw auto;
  grid-template-rows: auto auto;
}

.partner-grid-item {
  width: 16%;
  padding: 1.25em 1em;
  background-color: #fff;
  box-shadow: 6px 6px 50px 0 rgba(0, 0, 0, 0.1);
}

.tryout-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 1.5em;
  border-radius: 10px;
  background-color: #44946c;
  text-decoration: none;
}

.tryout-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 7.65em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.tryout-text {
  margin-right: 3.9vw;
  margin-left: 6.3vw;
  padding: 0.75em 0px;
  border-radius: 10px;
  background-color: #44946c;
  color: #fff;
  font-size: 2em;
  line-height: 1.2em;
  font-weight: 700;
  text-decoration: none;
}

.our-products {
  padding-top: 11em;
  text-align: center;
}

.overview-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 200px;
  padding-bottom: 200px;
  grid-column-gap: 78px;
}

.overview-section.pad-img-left {
  padding: 15em 8.5vw 15em 4.6vw;
}

.overview-section.pad-img-left.dec-bg {
  margin-top: -3em;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url('../images/dec-bg.png');
  background-position: 50% 0%;
  background-size: 100% 100%;
  background-repeat: repeat-x;
}

.overview-img {
  width: 41vw;
  -o-object-fit: contain;
  object-fit: contain;
}

.content-block {
  width: 42vw;
}

.content-block.align-left {
  text-align: right;
}

.content-block.left-align {
  text-align: left;
}

.overview-title {
  color: #afd1c0;
  font-size: 4.5em;
  line-height: 1.2em;
  font-weight: 700;
  letter-spacing: 0.05em;
}

.overview-subtitle {
  margin-bottom: 30px;
  font-family: Raleway, sans-serif;
  color: #001d0e;
  font-size: 2.5em;
  line-height: 1.2em;
  font-weight: 700;
}

.overview-desc {
  margin-bottom: 2em;
  font-family: Lato, sans-serif;
  font-size: 2.1em;
  line-height: 1.2em;
  font-weight: 400;
}

.granular-data {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 100px;
  grid-column-gap: 64px;
}

.granular-data.pad-img-right {
  margin-top: 0px;
  padding-top: 100px;
  padding-right: 4.6vw;
  padding-left: 8.5vw;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.carbon-data-service {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 100px;
  padding-top: 200px;
  padding-bottom: 200px;
  grid-column-gap: 97px;
}

.carbon-data-service.pad-img-right-cds {
  padding: 10% 8.5vw 10% 5.6vw;
}

.carbon-data-service.pad-img-right-cds.align-left {
  text-align: right;
}

.carbon-data-service.pad-img-right-cds.align-left.dec-bg {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url('../images/dec-bg.png');
  background-position: 50% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.footer {
  position: relative;
  padding: 50px 7.6vw 40px;
  border-bottom: 1px solid #e4ebf3;
}

.footer-form-two {
  padding: 64px;
  border-radius: 15px;
  background-color: rgba(68, 148, 108, 0.28);
}

.sign-up-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 1.6vw;
}

.footer-form-title {
  width: 17vw;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 2.2em;
  line-height: 1.2em;
  font-weight: 600;
}

.footer-form-block-two {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 1em;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 6px;
  background-color: #fff;
}

.subscribe-input {
  height: 48px;
  margin-bottom: 0px;
  border: 1px none #000;
}

.subscribe-input::-webkit-input-placeholder {
  color: #2b3d51;
  font-size: 1.2em;
  line-height: 1.2em;
}

.subscribe-input:-ms-input-placeholder {
  color: #2b3d51;
  font-size: 1.2em;
  line-height: 1.2em;
}

.subscribe-input::-ms-input-placeholder {
  color: #2b3d51;
  font-size: 1.2em;
  line-height: 1.2em;
}

.subscribe-input::placeholder {
  color: #2b3d51;
  font-size: 1.2em;
  line-height: 1.2em;
}

.footer-links-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 84px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 5%;
}

.footer-link {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: #000;
  font-size: 1.6em;
  line-height: 1.2em;
  font-weight: 500;
  text-decoration: none;
}

.footer-link:hover {
  color: rgba(26, 27, 31, 0.75);
}

.footer-social-block-three {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 64px;
}

.footer-social-media-icon {
  width: 36px;
  height: 36px;
}

.divider {
  width: 100%;
  height: 1px;
  margin-top: 7.8em;
  margin-bottom: 5.2em;
  background-color: #000;
}

.footer-bottom {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.7em;
  line-height: 1.2em;
}

.footer-copyright {
  color: #001d0e;
}

.footer-legal-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #001d0e;
}

.footer-legal-link {
  margin-left: 24px;
  color: #001d0e;
  text-decoration: none;
}

.footer-legal-link:hover {
  color: #3a4554;
}

.footer-left-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.footer-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.footer-right-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 53.8vw;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.footer-logo {
  width: 21vw;
  margin-left: -10px;
}

.gt-link {
  display: block;
  margin-top: 58px;
  color: #438f69;
  font-size: 1.6em;
  line-height: 1.2em;
  font-weight: 600;
  text-decoration: none;
}

.image {
  margin-top: 64px;
}

.get-in-touch {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 15em;
  padding-bottom: 11em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 3.1vw;
  background-image: url('../images/get-touch-bg.png');
  background-position: 50% 50%;
  background-size: 90%;
  background-repeat: no-repeat;
}

.form-block {
  position: relative;
  top: -150px;
  width: 39.6vw;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: -1px 15px 20px 0 rgba(51, 51, 51, 0.1);
}

.heading-2 {
  margin-bottom: 15px;
  color: #fff;
  font-size: 4.5em;
  line-height: 1em;
}

.form {
  padding: 12% 2.8vw;
}

.get-touch-desc {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2em;
  line-height: 1.2em;
  font-weight: 400;
}

.text-field {
  height: 48px;
  margin-bottom: 24px;
  padding: 30px 32px;
  border-style: solid;
  border-width: 1.5px;
  border-color: rgba(10, 20, 47, 0.38);
  border-radius: 5px;
}

.text-field::-webkit-input-placeholder {
  color: #0a142f;
  font-size: 1.14em;
  line-height: 1.15em;
  font-weight: 600;
}

.text-field:-ms-input-placeholder {
  color: #0a142f;
  font-size: 1.14em;
  line-height: 1.15em;
  font-weight: 600;
}

.text-field::-ms-input-placeholder {
  color: #0a142f;
  font-size: 1.14em;
  line-height: 1.15em;
  font-weight: 600;
}

.text-field::placeholder {
  color: #0a142f;
  font-size: 1.14em;
  line-height: 1.15em;
  font-weight: 600;
}

.text-field.text-area {
  min-height: 11.6vw;
}

.get-in-touch-hero {
  width: 32.8vw;
  margin-top: -24px;
}

.sticky-bar {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1000;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f9f9f9;
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.1);
}

.sticky-bar.event-pg {
  padding-left: 8.4vw;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.sticky-bar-wrapper {
  float: left;
}

.sticky-tab {
  display: inline-block;
  margin-right: 2.5vw;
  margin-left: 2.5vw;
  padding: 1em 0px;
  font-family: Raleway, sans-serif;
  color: #4e4e4e;
  font-size: 1.7em;
  line-height: 1.2em;
  font-weight: 600;
  text-decoration: none;
}

.sticky-tab:visited {
  border-bottom: 5px solid #44946c;
}

.sticky-tab.w--current {
  border-bottom: 5px solid #44946c;
  color: #000;
}

.sticky-tab.left-align {
  margin-right: 5vw;
  margin-left: 0vw;
}

.dataset {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 15em;
  padding-bottom: 0%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url('../images/ds-sec-bg.png');
  background-position: 50% 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  text-align: center;
}

.swiper-slide {
  position: relative;
  width: 51vw;
  height: 35vw;
  box-shadow: 5px 5px 9px 0 rgba(0, 0, 0, 0.1);
}

.slide-banner {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  display: none;
  height: 8.6vw;
  padding-bottom: 36px;
  padding-left: 40px;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  grid-column-gap: 42px;
  background-image: linear-gradient(178deg, rgba(0, 0, 0, 0.65), hsla(0, 0%, 100%, 0));
  text-align: left;
}

.slide-banner-text {
  font-family: Raleway, sans-serif;
  color: #fff;
  font-size: 1.4em;
  line-height: 1.2em;
  font-weight: 800;
}

.slide-banner-subtitle {
  max-height: 2.4em;
  max-width: 34vw;
  margin-top: 8px;
  font-family: Raleway, sans-serif;
  color: #fff;
  font-size: 1.14em;
  line-height: 1.2em;
  font-weight: 500;
}

.icon {
  width: auto;
  height: 2em;
}

.icon.primary {
  color: #2c6b4c;
}

.text-icon-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 40px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  grid-column-gap: 16px;
  color: #fff;
  font-size: 1.4em;
  line-height: 1.2em;
  font-weight: 600;
  text-decoration: none;
}

.text-icon-link.cs-link {
  font-family: Lato, sans-serif;
  font-size: 2.1em;
  font-weight: 700;
}

.text-icon-link.events-see-all {
  position: absolute;
  left: auto;
  top: auto;
  right: 20%;
  bottom: 14%;
  margin-right: 0px;
  color: #44946c;
  font-size: 1.7em;
  font-weight: 700;
}

.slide-img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.slider-arrow {
  position: relative;
  left: -25%;
  width: 3.2vw;
}

.slider-arrow.right {
  left: 0%;
  right: -25%;
}

.right-arrow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.signup-box-flow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  grid-column-gap: 24px;
}

.su-dec-arrow {
  position: relative;
  top: 13px;
  right: 8px;
  margin-left: 11px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -o-object-fit: contain;
  object-fit: contain;
}

.case-study {
  padding-top: 15em;
  padding-bottom: 15em;
  text-align: center;
}

.cs-slide-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  grid-column-gap: 3.4vw;
}

.cs-slider {
  height: 48.6em;
  margin-top: 3.7em;
  background-color: transparent;
  background-image: url('../images/case-study-bg.png');
  background-position: 50% 50%;
  background-size: 100% 100%;
  background-repeat: repeat-x;
}

.cs-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.cs-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 31.8vw;
  padding: 3.5em 1.9vw 4.7em 2.7vw;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  grid-row-gap: 5%;
  border: 1px solid #cfcfcf;
  border-radius: 10px;
  background-color: #fff;
}

.div-block-5 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  grid-column-gap: 2.4vw;
}

.cs-card-para {
  max-width: 23.4vw;
  padding-left: 3px;
  font-family: Lato, sans-serif;
  font-size: 1.7em;
  line-height: 1.5em;
}

.cs-card-para.upper-cs {
  max-width: 23vw;
  font-family: Lato, sans-serif;
  line-height: 1.2em;
  font-weight: 400;
}

.cs-img {
  width: 21vw;
  padding-right: 42px;
  padding-left: 42px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#afd1c0), to(#5da17f));
  background-image: linear-gradient(180deg, #afd1c0, #5da17f);
  -o-object-fit: contain;
  object-fit: contain;
}

.cs-left-aside {
  padding: 4.8em 75px 6.3em 1.6vw;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #44946c;
}

.image-2 {
  max-width: 13vw;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.cs-card-title {
  margin-bottom: 8px;
  font-family: Lato, sans-serif;
  font-size: 1.7em;
  line-height: 1.4em;
  font-weight: 700;
}

.cs-card-title.cs-card-top {
  margin-top: 0px;
  margin-bottom: 10%;
  font-weight: 700;
}

.cs-slide {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.events {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10em;
  padding-top: 15em;
  padding-bottom: 15em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.event-card {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 32vw;
  margin-left: 72px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.event-img {
  width: 32vw;
  height: 32vw;
}

.event-title {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 28px;
  padding-bottom: 38px;
  padding-left: 38px;
  background-color: #fff;
  font-size: 1.7em;
  line-height: 1.2em;
}

.event-date {
  position: absolute;
  left: auto;
  top: -7%;
  right: 12%;
  bottom: auto;
  z-index: 10;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 7.3vw;
  height: 7.3vw;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #44946c;
  font-family: Raleway, sans-serif;
  color: #fff;
  font-size: 2.1em;
  line-height: 1.2em;
  font-weight: 700;
  text-align: center;
}

.event-hero {
  max-width: 30vw;
  margin-left: 80px;
  padding: 4.5em 50px 4.5em 20px;
  background-color: #f9f9f9;
}

.events-description {
  font-family: Lato, sans-serif;
  font-size: 1.7em;
  line-height: 1.2em;
  font-weight: 400;
}

.event-box-dec {
  position: absolute;
  left: -16%;
  top: 27%;
  right: auto;
  bottom: auto;
  z-index: -1;
  width: 100%;
  height: 70%;
  border: 4px solid #4e4e4e;
}

.cs-left-arrow-wrapper {
  left: 40%;
  bottom: -2em;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.cs-right-arrow-wrapper {
  right: 40%;
  bottom: -2em;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.bgvideo {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.introduction-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  grid-column-gap: 8.5vw;
}

.nav-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.div-block-7 {
  margin-bottom: 52px;
}

.text-block-2 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.div-block-8 {
  padding-left: 12px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.white-paragraph-cs {
  width: 22vw;
  max-width: 31.3vw;
  margin-bottom: 2em;
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 1.7em;
  line-height: 1.2em;
  font-weight: 400;
}

.div-block-9 {
  display: none;
}

.swiper {
  width: 85%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.swiper-button-prev {
  margin-right: 1vw;
  margin-left: 2.5vw;
}

.swiper-button-next {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.swiper-button-next.right-arrow {
  margin-right: 2.5vw;
  margin-left: 1vw;
}

.dataset-hero {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: -1px;
  padding: 2em 9.8vw 15em 8.1vw;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #44946c;
}

.white-btn {
  padding: 1.1em 2.5em;
  border-radius: 10px;
  background-color: #fff;
  color: #44946c;
  font-size: 2em;
  line-height: 1.15em;
  font-weight: 800;
  text-transform: uppercase;
}

.white-btn.cds-card-btn {
  margin-top: 0px;
  padding: 0.5em 1.3em;
  font-family: Lato, sans-serif;
  font-size: 2em;
  font-weight: 700;
  text-transform: capitalize;
}

.white-btn.dataset-hero {
  padding-top: 1.1em;
  padding-bottom: 1.1em;
}

.white-btn.gcloud-btn {
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  text-transform: capitalize;
}

.white-btn.dsd {
  padding: 1em 1.75em;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.7em;
  font-weight: 700;
  text-transform: capitalize;
}

.ds-hero-title {
  position: relative;
  margin-top: 0px;
  margin-bottom: 0.5em;
  color: #fff;
  font-size: 5.7em;
  line-height: 1.15em;
  font-weight: 800;
}

.ds-hero-para {
  width: 48vw;
  margin-bottom: 2.6em;
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2em;
  line-height: 1.2em;
  font-weight: 400;
  text-align: left;
}

.swiper-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 4.5em;
  padding-bottom: 4.5em;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.ds-categories {
  padding-top: 10em;
  padding-bottom: 10em;
  text-align: center;
}

.category-sub {
  margin-bottom: 1.7em;
  font-family: Lato, sans-serif;
  font-size: 2.28em;
  line-height: 1.15em;
}

.cat-sub-caption {
  margin-bottom: 5.4em;
  font-family: Lato, sans-serif;
  color: #323232;
  font-size: 2.14em;
}

.ds-category-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding: 2.6em 1vw 2.6em 2.2vw;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border: 1px solid #e8e8e8;
  border-radius: 8.7px;
  background-color: #fff;
  text-align: left;
  text-decoration: none;
}

.cat-card-img {
  width: 5.6vw;
  -o-object-fit: contain;
  object-fit: contain;
}

.ds-card-title {
  font-family: Lato, sans-serif;
  color: #000;
  font-size: 2.4em;
  line-height: 1.2em;
  font-weight: 700;
}

.ds-card-subtitle {
  font-family: Lato, sans-serif;
  color: #000;
  font-size: 1.5em;
  line-height: 1.2em;
}

.demo-banner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 13.9em;
  padding: 5.25em 16.5vw;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(68, 148, 108, 0.54);
  text-align: center;
}

.demo-banner-title {
  margin-bottom: 1.5em;
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 4.5em;
  line-height: 1.18em;
  letter-spacing: 0.05em;
}

.img-backdrop {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  z-index: -1;
  width: 100%;
}

.backdrop-image {
  width: 100%;
  height: 100vh;
}

.transparent-cover {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.87);
}

.laep-hero {
  height: 85vh;
  padding-top: 10em;
  padding-left: 8.2vw;
  font-weight: 500;
}

.laep-title {
  width: 560px;
  margin-top: 0.25em;
  color: #fff;
  font-size: 5.7em;
  line-height: 1.15em;
  font-weight: 800;
}

.laep-hero-highlight {
  color: #75fcac;
  font-size: 4.2em;
}

.laep-hero-para {
  margin-bottom: 2em;
  color: #fff;
  font-size: 2.1em;
  line-height: 1.2em;
}

.primary-divider-sm {
  width: 97px;
  height: 2px;
  margin-bottom: 2.8em;
  background-color: #75fcac;
}

.hiw-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 5.7em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: url('../images/hiw-bg.png');
  background-position: 0px 0px;
  background-size: contain;
  background-repeat: no-repeat;
  text-align: center;
}

.hiw-video {
  width: 68vw;
  height: 40vh;
  border-radius: 16px;
}

.laep-offer-section {
  padding-top: 10em;
}

.section-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 7.2vw;
  padding-left: 8.6vw;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 1.6vw;
}

.section-divder {
  width: 100%;
  height: 4px;
  background-color: #44946c;
}

.section-heading {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  padding-right: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: #44946c;
  font-size: 2.8em;
  line-height: 1.2em;
  font-weight: 700;
}

.offer-cards {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 8.6vw;
  margin-left: 8.6vw;
  grid-column-gap: 2.08vw;
}

.offer-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 27vw;
  height: 31vw;
  margin-top: 10em;
  padding: 4em 2.5vw;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 4px 4px 19px 5px rgba(0, 0, 0, 0.1);
}

.offer-img {
  width: 6.3vw;
}

.offer-text {
  margin-bottom: 1.2em;
  font-family: Lato, sans-serif;
  font-size: 2.1em;
  line-height: 1.15em;
  font-weight: 400;
  text-align: center;
}

.offer-para {
  margin-top: 3.9em;
  margin-right: 11.4vw;
  margin-left: 11.4vw;
  font-family: Lato, sans-serif;
  font-size: 2.1em;
  line-height: 1.15em;
  font-weight: 400;
  text-align: center;
}

.screenshot-section {
  padding-top: 8.1em;
}

.screenshots {
  display: -ms-grid;
  display: grid;
  margin-top: 11.5em;
  margin-right: 8.6vw;
  margin-left: 8.6vw;
  grid-auto-columns: 1fr;
  grid-column-gap: 1.25vw;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.screenshot {
  border-radius: 5px;
}

.howdoeswork-sec {
  padding-top: 15em;
}

.hdiw {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10em;
  padding: 4.88em 5.8vw 7.8em 5vw;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 3.6vw;
  background-image: url('../images/hiw-bg-ov-img.png');
  background-position: 100% 50%;
  background-size: 80% 100%;
  background-repeat: no-repeat;
}

.hdwim {
  width: 32.6vw;
}

.heading-3 {
  margin-top: 0px;
  margin-bottom: 0.775em;
  color: #fff;
  font-size: 2.8em;
}

.paragraph-3 {
  width: 52.8vw;
  font-family: Lato, sans-serif;
  color: hsla(0, 0%, 100%, 0.86);
  font-size: 2.28em;
  line-height: 1.15em;
  letter-spacing: 0.05em;
}

.list-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  margin-bottom: 4.7em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 4.1vw;
}

.list-container.img-right {
  padding-left: 8.6vw;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.list-block {
  position: relative;
  left: -16%;
}

.list-block.left {
  left: 0%;
}

.list-heading {
  margin-bottom: 1.25em;
  font-size: 2.8em;
  line-height: 1.15em;
}

.list-img {
  position: absolute;
  left: 56%;
  top: 50%;
  right: auto;
  bottom: auto;
  width: 28.9vw;
  margin-right: auto;
  margin-left: auto;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.list-img.right {
  left: 44%;
}

.list-img.right.img2 {
  width: 37.9vw;
}

.hiw-list-item {
  margin-bottom: 1em;
  font-family: Lato, sans-serif;
  font-size: 2.1em;
  line-height: 1.15em;
}

.feature-lists {
  margin-top: 9.4em;
}

.hero-split-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 46%;
  margin-top: -18px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.margin-bottom-24px {
  margin-bottom: 2.5em;
  font-family: Lato, sans-serif;
  font-size: 2.14em;
  line-height: 1.2em;
  font-weight: 400;
}

.heading-9 {
  margin-bottom: 1.3em;
  font-size: 2.857142857142857em;
  line-height: 1.15em;
}

.section-2 {
  margin-top: 58px;
}

.section-2-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 7.7em;
  padding-right: 10.9vw;
  padding-left: 8.2vw;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  grid-column-gap: 7.8vw;
}

.icon-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 1.07em 1.8em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 12px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 6px 6px 10px 0 rgba(68, 148, 108, 0.1);
  color: #44946c;
  font-size: 2em;
  font-weight: 800;
  text-decoration: none;
  text-transform: uppercase;
}

.icon-btn.primary {
  background-color: #44946c;
  color: #fff;
}

.dummy-img {
  height: 20px;
}

.rc-section {
  padding-top: 10em;
  padding-bottom: 10em;
}

.related-contents {
  position: relative;
  margin-top: 9.2em;
}

.rc-links {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-right: 15vw;
  padding-bottom: 6.1em;
  padding-left: 4.2vw;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.rc-link-text {
  margin-bottom: 0.3em;
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 3.4em;
  line-height: 1.2em;
  font-weight: 700;
}

.link-icon-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  grid-column-gap: 14px;
  font-family: Lato, sans-serif;
  color: #75fcac;
  font-size: 2.5em;
  line-height: 1.15em;
  text-decoration: none;
}

.case-study-laep {
  text-align: center;
}

.section-3 {
  padding-top: 15em;
}

.laep-features {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10.7em;
  margin-bottom: 10.7em;
  padding: 12em 9vw;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  grid-column-gap: 3.6vw;
  background-color: #fff;
  box-shadow: 4px 4px 19px 0 rgba(0, 0, 0, 0.05);
}

.image-10 {
  width: 8.3vw;
  border-radius: 11px;
  background-color: #1e7145;
}

.heading-11 {
  margin-top: 1.2em;
  margin-bottom: 0.625em;
  font-family: Lato, sans-serif;
  font-size: 2.8em;
  line-height: 1.15em;
  font-weight: 700;
}

.text-block-5 {
  width: 23vw;
  font-family: Lato, sans-serif;
  font-size: 2.1em;
  line-height: 1.15em;
}

.slide-nav-2 {
  display: none;
}

.image-11 {
  width: 33vw;
  padding-left: 0px;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.categories {
  margin-right: 7.9vw;
  margin-left: 7.9vw;
  grid-column-gap: 1.6vw;
  grid-row-gap: 3.5em;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.image-14 {
  width: 28vw;
}

.image-15 {
  position: absolute;
  width: 57vw;
  height: 100%;
}

.image-16 {
  width: 55vw;
  float: right;
}

.gradient {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 100%;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), color-stop(100%, #000), to(#fff));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.1), #000 100%, #fff);
}

.gradient.rotate {
  background-image: linear-gradient(119.9deg, #000, rgba(0, 0, 0, 0.1) 100%, transparent);
}

.faq-section {
  margin-top: 10em;
  margin-bottom: 10em;
  padding-top: 5em;
  padding-right: 5.1vw;
  padding-left: 5.1vw;
  background-color: rgba(68, 148, 108, 0.23);
}

.faq-section.laep {
  margin-top: 16em;
}

.faq-heading {
  margin-top: 0px;
  margin-bottom: 0.8em;
  font-size: 3.4em;
  line-height: 1.15em;
}

.faq-question-block {
  position: relative;
  padding-top: 3em;
  padding-bottom: 3em;
  border-bottom: 1px solid rgba(68, 148, 108, 0.23);
}

.faq-arrow {
  position: absolute;
  left: 0%;
  top: 2.5em;
  right: auto;
  bottom: auto;
}

.faq-question {
  margin-left: 2.38em;
  font-family: Lato, sans-serif;
  font-size: 2em;
  line-height: 1.15em;
}

.cds-section {
  padding-top: 10em;
}

.cds-section-wrapper {
  display: -ms-grid;
  display: grid;
  margin-top: 10em;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  background-color: rgba(68, 148, 108, 0.54);
}

.cds-card {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10em 3.75vw 8em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.cds-card-title {
  margin-top: 1.5em;
  margin-bottom: 1.05em;
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2.8em;
  line-height: 1.15em;
}

.cds-card-para {
  margin-bottom: 2.5em;
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2.28em;
  line-height: 1.15em;
}

.cds-hero {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 85vh;
  padding-top: 13.7em;
  padding-bottom: 2em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 500;
}

.cds-title {
  margin-top: 0px;
  margin-bottom: 0.325em;
  color: #fff;
  font-size: 5.7em;
  line-height: 1.15em;
  font-weight: 800;
}

.cds-hero-btn-group {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 6.4em;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  grid-column-gap: 2.5vw;
}

.outlined-btn {
  padding: 1.07em 1.4em;
  border: 2px solid #75fcac;
  border-radius: 10px;
  background-color: transparent;
  font-size: 2em;
  line-height: 1.15em;
  font-weight: 800;
  text-align: center;
  text-transform: uppercase;
}

.cds-section-1 {
  padding-top: 15em;
}

.cds-card-img {
  width: 10.7vw;
  height: 8.8vw;
  border-radius: 14px;
  background-color: #1e7145;
}

.dec-img {
  position: absolute;
  left: auto;
  top: -2%;
  right: -4%;
  bottom: auto;
  z-index: -1;
  width: 102%;
  height: 104%;
  border-radius: 10px;
  background-color: #44946c;
  -webkit-transform: skew(-7deg, 0deg);
  -ms-transform: skew(-7deg, 0deg);
  transform: skew(-7deg, 0deg);
}

.platform-desc-section {
  padding-top: 5.7em;
}

.plt-grid {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  grid-column-gap: 4.6vw;
  grid-row-gap: 4.3em;
}

.plt-img {
  width: 5vw;
}

.plt-para {
  margin-top: 0.8em;
  margin-bottom: 0px;
  font-family: Lato, sans-serif;
  font-size: 2.1em;
  line-height: 1.15em;
  font-weight: 400;
}

.plt-selector {
  width: 10.6vw;
  font-family: Lato, sans-serif;
  color: #44946c;
  font-size: 2.28em;
  font-weight: 700;
}

.cds-section-heading {
  margin-top: 0px;
  margin-bottom: 0.275em;
  color: #fff;
  font-size: 2.8em;
  line-height: 1.15em;
}

.text-block-8 {
  margin-bottom: 1.6em;
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2.28em;
  line-height: 1.15em;
}

.cds-section-list {
  margin-bottom: 1em;
  color: #fff;
  font-size: 2.1em;
  line-height: 1.15em;
}

.cds-section-img {
  width: 47.4vw;
  border-radius: 10px;
}

.cds-section-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10em;
  padding: 4.88em 5.8vw 7.8em 5vw;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 3.6vw;
  background-image: url('../images/hiw-bg-ov-img.png');
  background-position: 100% 50%;
  background-size: 95% 100%;
  background-repeat: no-repeat;
}

.tabs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 9.6em;
  margin-right: 5.6vw;
  margin-left: 8.6vw;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.tabs-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 7.6vw;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.tab-link {
  padding-top: 2.5em;
  padding-bottom: 2.5em;
  border-left: 4px solid rgba(7, 149, 43, 0.37);
  background-color: transparent;
}

.tab-link.w--current {
  border-left: 4px solid #44946c;
  background-color: transparent;
}

.about-dec {
  position: relative;
  top: -136px;
  right: -35px;
  z-index: 1;
  width: 43.8vw;
  margin-top: 0em;
  padding-right: 0px;
}

.cs-title {
  margin-top: 0.25em;
  margin-bottom: 0.325em;
  color: #fff;
  font-size: 5.7em;
  line-height: 1.15em;
  font-weight: 800;
}

.cs-hero-text {
  margin-bottom: 2em;
  color: #fff;
  font-size: 2.1em;
  line-height: 1.2em;
}

.cs-desc-section {
  margin-top: 15em;
  margin-bottom: 12.8em;
  padding-right: 13vw;
  padding-left: 13vw;
}

.cs-desc {
  margin-bottom: 2.28em;
  font-size: 2.8em;
  line-height: 1.825em;
  font-weight: 600;
}

.cs-desc-image {
  width: 100%;
  margin-bottom: 6em;
  border-radius: 9px;
}

.cs-desc-sm {
  margin-bottom: 0.83em;
  font-size: 1.7em;
  line-height: 1.15em;
  font-weight: 500;
}

.cs-section-2 {
  padding-right: 13vw;
  padding-left: 13vw;
}

.cs-sec-heading {
  margin-bottom: 0.83em;
  font-family: Lato, sans-serif;
  font-size: 3.4em;
  letter-spacing: 0.05em;
}

.cs-sec2-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 8.5em;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  grid-column-gap: 3.2vw;
}

.cs-sec-2-desc {
  font-weight: 500;
}

.cs-sec-2-desc p {
  font-weight: 500;
}

.cs-sec-2-img {
  width: 30vw;
  margin-top: -73px;
  -o-object-fit: contain;
  object-fit: contain;
}

.upcoming-event-banner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 8.8vw;
  margin-left: 8.8vw;
  grid-column-gap: 3.4vw;
  border-radius: 10px;
  box-shadow: 0 10px 8px 0 rgba(68, 148, 108, 0.1);
}

.ue-aside {
  width: 50%;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.text-span-2 {
  color: #44946c;
}

.text-span-3 {
  color: #44946c;
}

.case-study-cs {
  margin-top: 13.5em;
  margin-bottom: 18em;
  text-align: left;
}

.cs-sec-heading-2 {
  margin-bottom: 3.7em;
  margin-left: 13vw;
  font-family: Lato, sans-serif;
  font-size: 3.4em;
  letter-spacing: 0.05em;
}

.events-hero {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100vh;
  margin-top: -1px;
  padding-right: 3vw;
  padding-left: 8.6vw;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  background-color: #44946c;
}

.hero-wrapper-events {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  grid-column-gap: 5.5vw;
}

.ev-hero-para {
  width: 46.6vw;
  margin-bottom: 2.6em;
  font-family: Raleway, sans-serif;
  color: #fff;
  font-size: 2.5em;
  line-height: 1.2em;
  font-weight: 600;
  text-align: left;
}

.ev-hero-title {
  position: relative;
  margin-top: 0px;
  margin-bottom: 0.5em;
  color: #fff;
  font-size: 5.7em;
  line-height: 1.15em;
  font-weight: 800;
}

.event-aside {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20.7em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.events-hero-img {
  width: 36vw;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  -o-object-fit: contain;
  object-fit: contain;
}

.ue-section {
  margin-bottom: 7.5em;
  padding-top: 10em;
  padding-bottom: 10em;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 6px 6px 50px 0 rgba(0, 0, 0, 0.04);
}

.events-section {
  padding-bottom: 8em;
}

.image-19 {
  width: 50%;
}

.ue-title {
  margin-top: 0.8em;
  margin-bottom: 0.8em;
  font-size: 2.1em;
  line-height: 1.15em;
  font-weight: 600;
}

.upcomming-tag {
  color: #7c7c7c;
  font-size: 1.7em;
  font-weight: 700;
}

.about-heading {
  margin-top: 0px;
  color: #4e4e4e;
  font-size: 2.57em;
  line-height: 1.15em;
  font-weight: 800;
}

.paragraph-8 {
  width: 35vw;
  padding-right: 0px;
  padding-left: 0px;
  font-size: 2.857142857142857em;
  line-height: 1.2em;
  font-weight: 600;
  letter-spacing: 0.1px;
}

.paragraph-11 {
  width: 34vw;
  font-size: 3.4285714285714284em;
  line-height: 1.2em;
  font-weight: 600;
  letter-spacing: 0.15px;
}

.teams-heading {
  margin-top: 3.5em;
  margin-bottom: 1.5em;
  padding-bottom: 0px;
  color: #44946c;
  font-size: 4.571em;
}

.team-img {
  padding: 2px;
  -o-object-fit: contain;
  object-fit: contain;
}

.team-name {
  margin-top: 0.6em;
  margin-bottom: 0.3em;
  font-family: Lato, sans-serif;
  font-size: 2.5714285714285716em;
}

.team-role {
  margin-top: 0px;
  margin-bottom: 1.28em;
  font-family: Lato, sans-serif;
  color: #44946c;
  font-size: 2em;
  line-height: 1.15em;
}

.team-desc {
  margin-bottom: 2.3em;
  font-family: Lato, sans-serif;
  color: #7c7c7c;
  font-size: 1.7142857142857142em;
  line-height: 1.5em;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.05px;
}

.div-block-32 {
  margin-right: 75vw;
}

.section-7 {
  margin-right: 78vw;
}

.linked-profile {
  width: 3vw;
  height: 3em;
}

.teams-card {
  padding: 4em 3vw;
  background-color: #fff;
  box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
}

.ue-subtitle {
  margin-bottom: 1.2em;
  font-size: 2.1em;
  line-height: 1.15em;
  font-weight: 500;
}

.ue-datetime-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  grid-column-gap: 2.5vw;
}

.ue-datetime {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 16px;
}

.text-block-9 {
  font-size: 1.4em;
  line-height: 1.15em;
  font-weight: 500;
}

.events-card {
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 4px 4px 40px 0 rgba(0, 0, 0, 0.1);
  color: #000;
  text-decoration: none;
}

.events-grid {
  display: -ms-grid;
  display: grid;
  margin-top: 5em;
  padding-right: 6vw;
  padding-left: 6vw;
  grid-auto-columns: 1fr;
  grid-column-gap: 1vw;
  grid-row-gap: 3.5em;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.event-card-img {
  width: 100%;
  height: 17.5vw;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  -o-object-fit: fill;
  object-fit: fill;
}

.ev-card-title {
  margin-top: 1.15em;
  margin-bottom: 0.9em;
  font-family: Lato, sans-serif;
  font-size: 1.4em;
  line-height: 1.15em;
  font-weight: 700;
}

.ev-card-subtitle {
  font-family: Lato, sans-serif;
  font-size: 1.14em;
  line-height: 1.18em;
}

.ev-card-content {
  padding-right: 2em;
  padding-bottom: 2.4em;
  padding-left: 2em;
}

.ev-datetime-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 2.8em;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 1vw;
}

.card-datetime {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.14em;
  line-height: 1.15em;
  font-weight: 500;
}

.ev-datetime {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0.4em 0.9em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 10px;
  border-radius: 4px;
  background-color: #d1e3da;
}

.demo-banner-prm {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 5em;
  margin-bottom: 10em;
  padding: 12em 16.5vw;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #44946c;
  text-align: center;
}

.demo-banner-prm.product {
  margin-top: 10em;
  margin-bottom: 0em;
}

.demo-title {
  margin-top: 0px;
  margin-bottom: 1.3em;
  color: #fff;
  font-size: 4.5em;
  line-height: 1.15em;
  font-weight: 700;
}

.about-hero {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 0em;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.about-hero-dec {
  width: 20.6vw;
}

.about-hero-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 18em;
  grid-column-gap: 1.5vw;
}

.teams-hero {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 8.8em;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.teams-section {
  padding-right: 8.1vw;
  padding-left: 8.1vw;
  background-image: url('../images/hiw-bg.png');
  background-position: 0% 0%;
  background-size: 53%;
  background-repeat: no-repeat;
}

.div-block-36 {
  width: 6.4vw;
  height: 5px;
  background-color: #44946c;
}

.teams-hero-para-2 {
  width: 35.4vw;
  margin-right: 3.15vw;
  font-size: 1.7em;
  line-height: 1.15em;
  font-weight: 500;
}

.text-span-4 {
  color: #000;
}

.teams-grid {
  grid-column-gap: 2vw;
  grid-row-gap: 3.5em;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

.work-with-us-section {
  margin-top: 16em;
  margin-bottom: 10em;
  padding: 26.6em 15.8vw 14.7em;
  background-image: url('../images/wwu-bg.png');
  background-position: 0px 0px;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
}

.wwi-heading {
  margin-top: 0px;
  margin-bottom: 1.09em;
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 4.5em;
  line-height: 1.15em;
}

.wwu-desc {
  margin-bottom: 1.6em;
  color: #fff;
  font-size: 3.4em;
  line-height: 1.15em;
  font-weight: 600;
}

.section-9 {
  margin-bottom: 0.1em;
  padding-top: 7.1em;
  padding-bottom: 7.1em;
  background-color: #44946c;
  text-align: center;
}

.heading-17 {
  position: relative;
  margin-top: 0px;
  font-family: Raleway, sans-serif;
  color: #fff;
  font-size: 4.5em;
  font-weight: 800;
}

.left-panel {
  background-color: transparent;
}

.div-block-37 {
  padding-bottom: 0px;
  background-color: transparent;
}

.seach-field {
  position: relative;
  top: -8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 0px;
  padding-bottom: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #cecece;
  border-radius: 0.8em;
  background-color: #f5f5f5;
  outline-color: transparent;
  outline-offset: 0px;
  outline-style: dashed;
  outline-width: 3px;
}

.placehold {
  display: inline-block;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 2em;
  color: #747474;
  font-size: 1.8em;
}

.gcloud-section {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10em;
  margin-bottom: 10em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.gcloud-img {
  width: 44vw;
  padding-bottom: 8.1em;
}

.gcloud-caption {
  position: absolute;
  left: auto;
  top: auto;
  right: 0%;
  bottom: 0%;
  margin-right: 4vw;
  font-size: 4.5em;
  line-height: 1.15em;
}

.gcloud-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 7.7em;
  padding-bottom: 10em;
  padding-left: 8.4vw;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #44946c;
}

.cloud-img {
  position: absolute;
  left: auto;
  top: 10%;
  right: 4%;
  bottom: auto;
  width: 21.7vw;
}

.image-22 {
  width: 100%;
  height: 41em;
}

.event-heading {
  position: absolute;
  left: 0%;
  top: 40%;
  right: 0%;
  bottom: auto;
  color: #fff;
  font-size: 5.7em;
  line-height: 1.15em;
  text-align: center;
}

.event-section {
  margin-top: 41em;
  margin-bottom: 10em;
  padding-right: 8.6vw;
  padding-left: 8.6vw;
}

.event-desc-wrapper {
  padding-left: 3vw;
  border-left: 6px solid #44946c;
}

.event-desc p {
  margin-bottom: 1.8em;
  font-size: 2.1em;
}

.image-23 {
  display: inline-block;
  margin-top: -27em;
  margin-right: 3.5em;
  float: right;
}

.search-panel {
  margin-top: 8px;
  background-color: #cfe2d9;
}

.text-block-12 {
  display: inline-block;
  width: 12vw;
  height: 2.43em;
  margin-top: -227px;
  padding: 1.2em 0px 1.2em 2.8em;
  float: left;
  clear: none;
  font-size: 2.14em;
  font-weight: 600;
  -o-object-fit: fill;
  object-fit: fill;
}

.div-block-44 {
  width: 67vw;
  height: 7.14em;
  margin-top: 2em;
  background-color: transparent;
}

.dataset-card {
  border-radius: 8.06931px;
  background-color: transparent;
  box-shadow: 5px 5px 40px 0 rgba(0, 0, 0, 0.1);
}

.dataset-card-img {
  height: 24.3em;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.dataset-card-title {
  margin-top: 0px;
  margin-bottom: 0.9em;
  font-family: Lato, sans-serif;
  font-size: 1.42em;
  line-height: 1.15em;
  font-weight: 700;
}

.paragraph-15 {
  margin-bottom: 2.5em;
  font-size: 1em;
}

.card-block-1 {
  width: 7em;
  height: 2em;
  padding-top: 0px;
  border-radius: 3.3px;
  background-color: #d1e3da;
}

.card-block {
  width: 7em;
  height: 2em;
  border-radius: 3.3px;
  background-color: #a3d8b1;
  color: #a3d8b1;
}

.section-11 {
  position: relative;
  width: 100vw;
  height: 7.142em;
  margin-bottom: 8.785714285714286em;
  float: none;
  clear: none;
}

.dataset-cards {
  margin-top: 3.57em;
  grid-column-gap: 2.1vw;
  grid-row-gap: 3.57em;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto auto auto;
  grid-template-rows: auto auto auto;
}

.acc-header {
  position: relative;
  height: 5.714285714285714em;
  background-color: #d1e3da;
}

.ds-filter {
  height: 5.174em;
  background-color: #f5f5f5;
}

.image-26 {
  margin-top: 1.8em;
  margin-left: 1vw;
  float: none;
}

.ds-filterinput {
  margin-top: -17px;
  margin-left: 3.3vw;
  color: #7b7b7b;
  font-size: 1.1428571428571428em;
  font-weight: 600;
}

.text-block-15 {
  display: inline-block;
  margin-right: 0vw;
  margin-left: 1.3vw;
  padding-top: 18px;
  padding-bottom: 15px;
  font-size: 1.8571428571428572em;
  line-height: 1.4em;
  font-weight: 600;
}

.checkbox-container {
  margin-bottom: 2em;
  background-color: #fbfbfb;
}

.form-2 {
  margin-top: 1em;
  margin-bottom: 1em;
}

.form-block-2 {
  margin-bottom: 1em;
}

.div-block-53 {
  height: 5.714em;
  background-color: #d1e3da;
}

.text-block-16 {
  height: 5.714em;
  margin-left: 3.3em;
  padding-top: 1.3em;
  font-family: Tahoma, Verdana, Segoe, sans-serif;
  font-size: 1.4em;
  line-height: 1.4em;
  font-weight: 700;
}

.feature-img-wrapper {
  position: relative;
  left: -16%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.feature-img-wrapper.right {
  left: 16%;
}

.fback-circle {
  position: relative;
  z-index: -2;
  width: 53.48vw;
  height: 53.48vw;
  border-radius: 50%;
  background-color: rgba(68, 148, 108, 0.23);
}

.fcircle-front {
  position: absolute;
  left: auto;
  top: 4%;
  right: 5%;
  bottom: auto;
  z-index: -1;
  width: 51.9vw;
  height: 49vw;
  border-radius: 50%;
  background-color: rgba(68, 148, 108, 0.23);
}

.fcircle-front.right {
  left: auto;
  top: 4%;
  right: -2%;
  bottom: auto;
}

.feature-dec-inner {
  position: relative;
  right: auto;
  bottom: auto;
}

.datasets-detail-hero {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 6em 7.2vw 9.9em 8.6vw;
  grid-column-gap: 3.4vw;
  background-color: #44946c;
}

.dsd-btn-group {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 1vw;
}

.text-block-17 {
  color: #fff;
  font-size: 2.1em;
  line-height: 1.15em;
  font-weight: 600;
}

.paragraph-16 {
  width: 46vw;
  margin-top: 0.5em;
  color: #fff;
  font-size: 2.1em;
  font-weight: 700;
}

.dsd-section {
  padding-top: 6.3em;
  padding-right: 8.6vw;
  padding-left: 8.6vw;
}

.image-27 {
  width: 100%;
  margin-bottom: 5.28em;
}

.paragraph-17 {
  margin-bottom: 1.04em;
  font-weight: 500;
}

.dsd-section-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 9.7em;
  padding-bottom: 16.3em;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.image-28 {
  width: 42.4vw;
  -o-object-fit: contain;
  object-fit: contain;
}

.sample-data-card {
  margin-right: 8.6vw;
  margin-left: 8.6vw;
  padding: 6em 3.7em 5.2em 3.7vw;
  border-left: 6px solid #44946c;
  background-color: hsla(0, 0%, 80%, 0.23);
}

.sd-form-field-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  grid-column-gap: 4.8vw;
}

.sdfield {
  width: 40.1vw;
  height: 3.2em;
  margin-bottom: 0px;
  padding: 1.14em 1.2em;
  border: 1px solid #d8d8d8;
  font-size: 2em;
}

.heading-21 {
  margin-top: 0px;
  margin-bottom: 0.39em;
  color: #44946c;
  font-size: 3.4em;
  line-height: 1.15em;
  font-weight: 600;
}

.text-block-18 {
  font-size: 2.57em;
  line-height: 1.15em;
  font-weight: 600;
}

.text-block-19 {
  margin-top: 1.187em;
  margin-bottom: 2em;
  color: #3c3c3c;
  font-size: 2.28em;
  line-height: 1.15em;
  font-weight: 500;
}

.field-label {
  margin-bottom: 0.833em;
  color: #3c3c3c;
  font-size: 1.71em;
}

.image-29 {
  margin-top: -0.3em;
  margin-left: 1.4vw;
  padding-right: 0px;
  padding-left: 0px;
}

.image-30 {
  display: block;
  margin-top: 2.3em;
  margin-left: 1.4vw;
  float: left;
  clear: none;
  -o-object-fit: fill;
  object-fit: fill;
}

.text-block-20 {
  font-size: 2em;
  font-weight: 600;
}

.text-block-21 {
  margin-top: 1em;
  margin-left: 1.3vw;
  padding-left: 1.5em;
  font-size: 2em;
  font-weight: 600;
}

.checkbox-label {
  font-size: 1.4285714285714286em;
}

.checkbox-label-2 {
  font-size: 1.4em;
}

.checkbox-label-3 {
  font-size: 1.4em;
}

.checkbox-label-4 {
  font-size: 1.4em;
}

.checkbox-label-5 {
  font-size: 1.4em;
}

.text-block-22 {
  font-size: 2.142857142857143em;
  line-height: 2.6em;
  font-weight: 600;
}

.image-31 {
  float: right;
}

.dataset-filters {
  width: 25vw;
  height: 7.142em;
  margin-top: -22.2em;
  float: right;
}

.image-32 {
  margin-top: 3em;
}

.text-block-23 {
  margin-top: -0.9em;
  margin-left: 2.357142857142857em;
  font-size: 1.7142857142857142em;
  font-weight: 500;
}

.div-block-55 {
  width: 8vw;
  height: 3em;
  margin-top: 2.5em;
  margin-right: 1.4vw;
  margin-left: 0vw;
  float: right;
  border-radius: 0.5em;
  outline-color: #44946c;
  outline-offset: 0px;
  outline-style: solid;
  outline-width: 0.2em;
}

.text-block-24 {
  font-size: 1.8px;
}

.text-block-25 {
  padding-top: 0.25em;
  padding-left: 0.85vw;
  font-size: 1.8em;
  font-weight: 600;
}

.checkbox-label-6 {
  font-size: 1.4em;
}

.checkbox-label-7 {
  font-size: 1.4em;
}

.div-block-56 {
  margin-top: 10px;
}

.div-block-57 {
  width: 22vw;
  height: 7.142em;
  margin-bottom: 0em;
  background-color: #d1e3da;
}

.image-33 {
  margin-top: 3em;
  margin-left: 1.4vw;
}

.text-block-26 {
  margin-top: -0.8em;
  margin-left: 4vw;
  font-size: 2em;
  font-weight: 600;
}

.checkbox-field {
  padding-top: 0.6em;
}

.checkbox-field-2 {
  margin-top: 0.6em;
}

.form-block-3 {
  margin-top: 13px;
}

.text-block-27 {
  width: 25vw;
  margin-top: 1em;
  margin-left: 4.5vw;
  padding-top: 0.5em;
  padding-bottom: 0.5px;
  font-family: Lato, sans-serif;
  color: #44946c;
  font-size: 3.4285714285714284em;
  font-weight: 700;
}

.div-block-58 {
  width: 67vw;
  height: 0.4em;
  margin-top: -1em;
  margin-left: 30vw;
  background-color: #44946c;
  color: #44946c;
}

.section-13 {
  margin-bottom: 10em;
}

.rc-card {
  padding: 5.5em 3em;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border: 1px solid #e8e8e8;
  border-radius: 8.7px;
  background-color: #fff;
}

.text-block-28 {
  margin-bottom: 1.2em;
  font-family: Lato, sans-serif;
  font-size: 2.4285714285714284em;
  font-weight: 600;
}

.paragraph-18 {
  font-family: Lato, sans-serif;
  font-weight: 400;
}

.related-content-slider {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 8.7em;
  padding-right: 8.6vw;
  padding-left: 8.6vw;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  grid-column-gap: 1.6vw;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.slider-arrows {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 6.4em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.arrow-right {
  margin-left: 10vw;
}

.section-14 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 8.6vw;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  grid-column-gap: 4.7vw;
  background-color: #44946c;
}

.heading-22 {
  margin-top: 3.5em;
  margin-bottom: 0.625em;
  color: #fff;
  font-size: 5.7em;
  line-height: 1.15em;
}

.paragraph-19 {
  width: 46.5vw;
  margin-bottom: 8.4em;
  color: #fff;
  font-size: 2.6em;
}

.image-37 {
  position: relative;
  top: 90px;
  width: 40vw;
}

.section-15 {
  padding: 21em 8.6vw 14em;
}

.div-block-61 {
  padding-top: 1em;
  padding-bottom: 1em;
  border-left: 6px solid #44946c;
}

.paragraph-20 {
  padding-left: 2.9vw;
  font-weight: 500;
}

.section-16 {
  padding-top: 16.4em;
  padding-bottom: 16em;
}

.cs-dsd-page {
  margin-top: 0px;
  margin-bottom: 1.375em;
  margin-left: 8.6vw;
  font-family: Lato, sans-serif;
  font-size: 3.4em;
}

.map-container {
  margin-right: 8.6vw;
  margin-left: 8.6vw;
  padding: 2em 22px;
  background-color: #fff;
}

.text-block-29 {
  margin-bottom: 0.6em;
  font-family: Lato, sans-serif;
  color: #000;
  font-size: 2.1em;
  font-weight: 700;
}

.section-17 {
  padding-top: 2em;
  padding-bottom: 10em;
}

.list {
  margin-bottom: 6.9em;
  padding-left: 20px;
}

.faq-question-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 12px;
}

.faq-answer {
  display: none;
  overflow: hidden;
  margin-bottom: 0em;
  padding-top: 1em;
  padding-left: 2.8em;
  font-family: Lato, sans-serif;
  font-weight: 500;
}

.brand {
  display: none;
}

.brand-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.brand-link.mobile {
  display: none;
}

.mobile-logo {
  display: none;
}

.ts-table {
  margin-left: 8.6vw;
  padding-right: 8.6vw;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.ts-cell {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 1.3em;
  padding-bottom: 1.3em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.35);
  font-family: Lato, sans-serif;
  color: #202020;
  font-size: 2.1em;
  line-height: 1.15em;
}

.ts-cell.left {
  padding-left: 2.6vw;
  color: #000;
}

.ts-tabl {
  grid-column-gap: 0px;
}

.ts-cell-r {
  padding-top: 3em;
  padding-bottom: 3px;
  border-bottom: 1px solid #000;
}

.ts-heading {
  padding-top: 0.8em;
  padding-bottom: 0.8em;
  background-color: #efefef;
  font-family: Lato, sans-serif;
  font-size: 2.1em;
  line-height: 1.15em;
  font-weight: 400;
}

.ts-heading.left {
  padding-left: 2.6vw;
}

.card-footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  grid-column-gap: 0.7vw;
}

.dataset-card-content {
  padding: 1.6em 2.1em 2.42em;
}

.datasets-overview {
  margin-right: 5.6vw;
  margin-left: 2.64vw;
  padding-top: 3.85em;
  padding-bottom: 10em;
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  grid-column-gap: 3.1vw;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.search-field {
  height: 80px;
  margin-bottom: 0px;
  padding-left: 2em;
  border: 0px none #000;
  background-color: transparent;
  font-size: 1.8em;
}

.search-field::-webkit-input-placeholder {
  color: #747474;
  line-height: 1.15em;
  font-weight: 700;
}

.search-field:-ms-input-placeholder {
  color: #747474;
  line-height: 1.15em;
  font-weight: 700;
}

.search-field::-ms-input-placeholder {
  color: #747474;
  line-height: 1.15em;
  font-weight: 700;
}

.search-field::placeholder {
  color: #747474;
  line-height: 1.15em;
  font-weight: 700;
}

.search-icon {
  margin-right: 3em;
}

.image-38 {
  width: 1.5vw;
}

.filter-panel {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: -8px;
  padding-top: 0px;
  padding-right: 2.6em;
  padding-left: 1.8em;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.div-block-62 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-column-gap: 24px;
}

.clear-btn {
  padding: 0.5em 0.6em;
  border-style: solid;
  border-width: 2px;
  border-color: #44946c;
  border-radius: 8px;
  background-color: transparent;
  color: #000;
  font-size: 1.7em;
  line-height: 1.15em;
  font-weight: 600;
}

.text-block-33 {
  font-size: 1.4em;
  font-weight: 500;
}

.image-39 {
  width: 12px;
}

@media screen and (max-width: 991px) {
  .hero-heading {
    height: 50vh;
    margin-bottom: 0px;
    padding-top: 3.8em;
    padding-bottom: 32px;
    padding-left: 28px;
  }

  .hero-wrapper {
    margin-bottom: -40px;
    margin-left: 0.8vw;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .hero-split {
    max-width: 100%;
    margin-bottom: 24.6em;
  }

  .subheading {
    width: 50vw;
    max-width: 90vw;
    margin-bottom: 0px;
    padding-right: 13px;
    font-size: 1em;
  }

  .primary-text.section-title {
    margin-top: 2em;
    font-size: 1.4em;
  }

  .signup-form {
    width: 100%;
    margin-top: 40px;
  }

  .demo-form-container {
    max-width: 350px;
  }

  .navbar {
    padding-right: 28px;
    padding-left: 28px;
  }

  .heading {
    overflow: visible;
    margin-bottom: 0.6em;
    opacity: 1;
    font-size: 2.5em;
  }

  .primary-btn {
    padding: 6px 16px;
    border-radius: 2.4px;
    font-size: 0.7em;
  }

  .primary-btn.extra-pad-btn {
    padding: 6px 14px;
    border-radius: 3.2px;
    font-size: 0.6em;
  }

  .primary-btn.contact-btn {
    display: none;
  }

  .primary-btn.demo-sign-btn {
    width: 30vw;
    margin-top: 2.8em;
    margin-left: 1vw;
    padding: 1em 1.14em;
    border-radius: 4.8px;
    font-size: 1em;
  }

  .primary-btn.footer-submit-btn {
    width: 26.5%;
    height: 2em;
    padding-bottom: 1.8em;
    padding-left: 2.5em;
    border-radius: 4px;
    font-family: Montserrat, sans-serif;
    font-size: 1.4em;
    font-weight: 600;
  }

  .primary-btn.footer-submit-btn.mob {
    width: 40vw;
    margin-right: 2vw;
    padding-top: 15px;
    padding-bottom: 15px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #44946c;
    font-family: Montserrat, sans-serif;
    font-size: 0.8em;
    font-weight: 600;
  }

  .primary-btn.fom-btn {
    font-size: 0.7em;
  }

  .primary-btn.gt-submit {
    font-size: 0.7em;
  }

  .primary-btn.laep-su-btn {
    font-size: 1em;
  }

  .nav-menu {
    padding-top: 1em;
    padding-right: 1em;
    padding-left: 1em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    background-color: #fff;
  }

  .nav-link {
    margin-left: 1.35em;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }

  .nav-link.white-nav-link {
    margin-right: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    color: #131313;
  }

  .nav-link.primary {
    color: #44946c;
  }

  .navlogo {
    width: 31.25vw;
    margin-top: 0vw;
    margin-left: 0px;
  }

  .navlogo.whitelogo {
    margin-left: -10px;
  }

  .introduction-card {
    max-width: 61vw;
    margin-bottom: 16px;
  }

  .introduction {
    width: 100vw;
    height: 50em;
    padding: 64px 0px 64px 11vw;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    background-image: url('../images/Green-Bg.svg');
    background-position: 15% 50%;
    background-size: 135vw;
  }

  .white-title {
    font-size: 0.8em;
  }

  .white-title.intro-title {
    margin-bottom: 14px;
    font-size: 1em;
  }

  .white-paragraph {
    max-width: 61vw;
    font-size: 0.7em;
  }

  .white-paragraph.intro-para-2 {
    max-width: 61vw;
  }

  .introduction-icon.intro-icon-2 {
    width: 13.6vw;
    margin-left: -4.4vw;
  }

  .primary-title {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 1.4em;
  }

  .primary-title.category {
    position: relative;
    font-size: 2.5em;
  }

  .our-partners {
    margin-top: 32px;
    padding-top: 32px;
    padding-bottom: 45px;
    background-position: 70% 84px;
    background-size: 130%;
  }

  .subtitle {
    font-size: 1em;
  }

  .subtitle.product-subtitle {
    margin-top: 8px;
    margin-right: 100px;
    margin-left: 100px;
    font-size: 0.6em;
  }

  .partners-grid {
    margin-top: 4em;
    padding-right: 6.4vw;
    padding-left: 6.4vw;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }

  .partner-grid-item {
    width: 20%;
    padding: 6px;
  }

  .tryout-btn {
    border-radius: 3.2px;
  }

  .tryout-container {
    margin-top: 3.7em;
  }

  .tryout-text {
    font-size: 1em;
  }

  .our-products {
    margin-top: 0px;
    padding-top: 45px;
  }

  .overview-section.pad-img-left {
    padding: 48px 32px 48px 12px;
  }

  .overview-section.pad-img-left.dec-bg {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    background-position: 50% 50%;
    background-size: 100% 100%;
  }

  .overview-img {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .content-block {
    width: 50%;
  }

  .overview-title {
    margin-bottom: 1px;
    font-size: 1em;
  }

  .overview-subtitle {
    margin-bottom: 1.4em;
    font-size: 0.7em;
  }

  .overview-desc {
    margin-bottom: 16px;
    font-size: 0.7em;
  }

  .granular-data.pad-img-right {
    margin-top: 48px;
    padding: 24px 12px 24px 32px;
  }

  .carbon-data-service.pad-img-right-cds.align-left.dec-bg {
    margin-top: 0px;
    padding: 82px 12px 82px 32px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    background-position: 50% 50%;
  }

  .footer {
    margin-top: 35px;
    padding-right: 38px;
    padding-left: 38px;
  }

  .footer-form-two {
    width: 80vw;
    height: 40em;
    margin-right: 5vw;
    margin-bottom: 0px;
    margin-left: 5vw;
    padding: 2.8em 40px 3em 0.02vw;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #eef0f3;
  }

  .sign-up-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .footer-form-title {
    width: 50vw;
    margin-top: 2.25em;
    margin-bottom: 0.9em;
    margin-left: 2.5vw;
    padding-top: 0px;
    padding-right: 0px;
    color: #263238;
    font-size: 3.5em;
    font-weight: 600;
    text-align: center;
  }

  .footer-form-block-two {
    width: 100%;
    height: 5em;
    margin-right: 0.5vw;
    margin-left: 5.5vw;
    padding: 0.5em 2.4vw 0.5em 1vw;
    border-radius: 4px;
    box-shadow: 0 4px 5px 3px rgba(0, 0, 0, 0.07);
    opacity: 1;
  }

  .subscribe-input {
    height: 32px;
    font-family: Montserrat, sans-serif;
    color: #2b3d51;
    font-size: 1.5em;
  }

  .subscribe-input::-webkit-input-placeholder {
    font-size: 0.78em;
  }

  .subscribe-input:-ms-input-placeholder {
    font-size: 0.78em;
  }

  .subscribe-input::-ms-input-placeholder {
    font-size: 0.78em;
  }

  .subscribe-input::placeholder {
    font-size: 0.78em;
  }

  .subscribe-input.mob {
    font-size: 0.8em;
  }

  .footer-links-block {
    margin-top: 6em;
    padding-bottom: 2em;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    grid-column-gap: 4%;
    grid-row-gap: 3.5em;
  }

  .footer-link {
    font-size: 2.5em;
    font-weight: 500;
  }

  .footer-social-block-three {
    margin-top: 0.4em;
    margin-bottom: 4em;
  }

  .footer-social-media-icon {
    width: 4em;
    height: 3em;
  }

  .divider {
    margin-top: 3.5em;
    margin-bottom: 2em;
    background-color: #0a142f;
  }

  .footer-bottom {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    font-size: 0.8em;
    text-align: center;
  }

  .footer-copyright {
    margin-bottom: 28px;
    color: #0a142f;
    font-size: 1.5em;
  }

  .footer-legal-link {
    font-size: 1.5em;
  }

  .footer-left-section {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .footer-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .footer-right-section {
    width: auto;
    margin-top: 4em;
  }

  .footer-logo {
    width: 65.5vw;
    margin-left: 0px;
  }

  .gt-link {
    display: none;
  }

  .get-in-touch {
    margin-top: 0px;
    padding-top: 5.7em;
    padding-bottom: 5.7em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    grid-column-gap: 2.28em;
    grid-row-gap: 2.28px;
    background-position: 50% 100%;
  }

  .form-block {
    top: 0px;
    width: 74vw;
    margin-top: 2.28em;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .heading-2 {
    margin-top: 0px;
    margin-bottom: 0.7em;
    color: #000;
    font-size: 2em;
  }

  .form {
    padding: 2em;
  }

  .get-touch-desc {
    margin-right: -15px;
    padding-right: 0px;
    color: #000;
    font-size: 1.4em;
  }

  .text-field {
    height: 34px;
    margin-bottom: 12px;
    padding: 10px 14px;
  }

  .text-field::-webkit-input-placeholder {
    font-size: 0.7em;
  }

  .text-field:-ms-input-placeholder {
    font-size: 0.7em;
  }

  .text-field::-ms-input-placeholder {
    font-size: 0.7em;
  }

  .text-field::placeholder {
    font-size: 0.7em;
  }

  .get-in-touch-hero {
    margin-top: 0px;
    margin-left: 10vw;
  }

  .sticky-bar {
    display: block;
    margin-bottom: 64px;
    background-color: #fff;
  }

  .sticky-bar-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: auto;
    width: 100%;
    padding-right: 80px;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    background-color: #f9f9f9;
  }

  .sticky-tab {
    display: inline-block;
    margin-right: 20px;
    margin-left: 20px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    font-size: 0.7em;
    text-decoration: none;
  }

  .sticky-tab.w--current {
    border-bottom: 2px solid #44946c;
  }

  .dataset {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .swiper-slide {
    width: 70vw;
    height: 47vw;
  }

  .slide-banner {
    height: 11.8vw;
    padding-bottom: 14px;
    padding-left: 12px;
    grid-column-gap: 12px;
    grid-row-gap: 12px;
  }

  .slide-banner-text {
    font-size: 0.4em;
  }

  .slide-banner-subtitle {
    max-width: 50vw;
    margin-top: 2px;
    font-size: 0.3em;
  }

  .text-icon-link {
    margin-right: 14px;
    font-size: 0.4em;
  }

  .text-icon-link.cs-link {
    font-size: 0.8em;
  }

  .text-icon-link.events-see-all {
    right: 10%;
    bottom: 0%;
    font-size: 1em;
  }

  .case-study {
    margin-bottom: 0px;
    padding-top: 86px;
    padding-bottom: 40px;
  }

  .cs-slide-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }

  .cs-slider {
    height: 40em;
    margin-top: 32px;
    background-position: 50% 0%;
    background-size: 150%;
  }

  .cs-right {
    width: 56vw;
    padding: 26px 40px 36px 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .div-block-5 {
    width: 100%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .cs-card-para {
    max-width: none;
    font-size: 0.7em;
  }

  .cs-card-para.upper-cs {
    max-width: 41vw;
  }

  .cs-img {
    width: 34vw;
    padding-right: 12px;
    padding-left: 12px;
  }

  .cs-left-aside {
    padding: 35px 16px 40px 12px;
  }

  .image-2 {
    -o-object-fit: contain;
    object-fit: contain;
  }

  .cs-card-title {
    font-size: 0.7em;
  }

  .cs-card-title.cs-card-top {
    margin-top: 0px;
    margin-bottom: 16px;
    font-size: 0.7em;
  }

  .cs-slide {
    height: auto;
  }

  .events {
    padding-top: 40px;
    padding-bottom: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .event-card {
    width: 69vw;
    margin-right: 60px;
    margin-bottom: 48px;
    margin-left: 60px;
  }

  .event-img {
    width: 100%;
    height: auto;
  }

  .event-title {
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 18px;
    font-size: 0.8em;
  }

  .event-date {
    width: 16vw;
    height: 16vw;
    font-size: 1em;
  }

  .event-hero {
    position: relative;
    max-width: 50vw;
    margin-left: 60px;
    padding: 0px 0px 64px 14px;
  }

  .events-description {
    max-width: 50vw;
    font-size: 0.7em;
  }

  .event-box-dec {
    left: 3%;
    top: 8%;
    width: 90%;
    height: 85%;
  }

  .cs-left-arrow-wrapper {
    left: 30%;
    bottom: 16px;
  }

  .cs-right-arrow-wrapper {
    right: 30%;
    bottom: 16px;
  }

  .image-3 {
    width: 3vw;
  }

  .bgvideo {
    overflow: hidden;
    opacity: 0;
  }

  .introduction-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .nav-wrapper {
    width: auto;
    background-color: #fff;
  }

  .nav-wrapper.primary {
    background-color: #44946c;
  }

  .text-block-2 {
    font-size: 1em;
  }

  .white-paragraph-cs {
    width: 40vw;
    font-size: 0.7em;
  }

  .div-block-9 {
    position: absolute;
    left: auto;
    top: 0%;
    right: 0%;
    bottom: 0%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 6px 12px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
  }

  .div-block-11 {
    width: 30px;
    height: 100%;
    padding-right: 0px;
    padding-bottom: 0px;
    background-color: #fff;
    -webkit-filter: blur(10px);
    filter: blur(10px);
  }

  .dataset-hero {
    overflow: visible;
    margin-top: -1px;
    margin-bottom: 232px;
    background-image: url('../images/Rectangle-402.png');
    background-position: 0px 0px;
    background-size: contain;
  }

  .white-btn {
    position: relative;
    top: 0em;
    bottom: 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 1.2em;
    margin-right: 21.5vw;
    margin-left: 21.5vw;
    padding: 0.6em 1.4em 0.6em 2.2em;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    direction: ltr;
    font-size: 1.6em;
    font-weight: 800;
    text-transform: uppercase;
    white-space: break-spaces;
  }

  .white-btn.cds-card-btn {
    top: 0px;
    border-radius: 2px;
    font-size: 0.7em;
  }

  .white-btn.dataset-hero {
    background-image: none;
  }

  .white-btn.gcloud-btn {
    margin-left: 0vw;
    font-size: 1.4em;
  }

  .ds-hero-title {
    margin-bottom: 3.1em;
    margin-left: 4px;
  }

  .ds-hero-para {
    position: relative;
    left: 0%;
    top: auto;
    right: auto;
    bottom: 0%;
    display: inline-block;
    margin: -197px 0px 8.9em 9px;
    padding-right: 0px;
    direction: ltr;
    font-size: 1.8em;
    text-align: left;
    letter-spacing: 0.05px;
  }

  .ds-categories {
    margin-top: -13.5em;
    padding-top: 0em;
  }

  .category-sub {
    font-size: 1.5em;
    letter-spacing: 0.05px;
  }

  .cat-sub-caption {
    margin-right: 134px;
    margin-left: 77px;
    padding-right: 0px;
    padding-left: 53px;
    font-size: 1.5em;
  }

  .ds-category-card {
    background-color: #fff;
    color: #fff;
  }

  .cat-card-img {
    width: 6vw;
    margin-left: 20px;
  }

  .ds-card-title {
    margin-left: 21px;
  }

  .ds-card-subtitle.mob {
    margin-right: 30px;
    margin-left: 20px;
  }

  .demo-banner {
    margin-top: -12.4em;
  }

  .demo-banner-title {
    font-size: 3em;
  }

  .backdrop-image {
    height: 95vh;
  }

  .laep-hero {
    height: 80vh;
    padding-right: 28px;
    padding-left: 28px;
  }

  .laep-title {
    width: 290px;
    font-size: 2.8em;
  }

  .laep-hero-highlight {
    margin-top: 0em;
    font-size: 2.28em;
  }

  .laep-hero-para {
    margin-right: 18vw;
    font-size: 1.4em;
  }

  .hiw-section {
    padding-top: 0.1em;
  }

  .hiw-video {
    width: 82.8vw;
  }

  .laep-offer-section {
    padding-top: 5em;
  }

  .section-heading {
    font-size: 1.4em;
  }

  .offer-cards {
    margin-top: 5em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-row-gap: 3em;
  }

  .offer-card {
    width: 60.8vw;
    height: auto;
    margin-top: 0em;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .offer-text {
    font-size: 1em;
  }

  .offer-para {
    font-size: 1.07em;
  }

  .screenshot-section {
    padding-top: 4em;
  }

  .screenshots {
    margin-top: 5em;
    grid-row-gap: 2.8em;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .howdoeswork-sec {
    padding-top: 5em;
  }

  .hdiw {
    margin-top: 5em;
    padding-top: 2.5em;
    padding-bottom: 2.5em;
  }

  .heading-3 {
    font-size: 0.8em;
  }

  .paragraph-3 {
    font-size: 0.7em;
  }

  .list-container {
    margin-bottom: 3em;
  }

  .list-container.img-right {
    overflow: hidden;
  }

  .list-heading {
    font-size: 0.85em;
  }

  .hiw-list-item {
    font-size: 0.71em;
  }

  .feature-lists {
    margin-top: 5em;
  }

  .hero-split-2 {
    max-width: 100%;
    margin-bottom: 40px;
  }

  .section-2-wrapper {
    margin-bottom: -40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .rc-section.laep {
    padding-top: 2em;
    padding-bottom: 2em;
  }

  .related-contents {
    height: 71.42857142857143em;
    margin-top: 5em;
    margin-right: 0px;
    padding-right: 0px;
  }

  .rc-links {
    height: 100%;
    padding-bottom: 0em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .rc-link-text {
    position: static;
    left: 0px;
    top: 315px;
    margin-top: 0em;
    padding-top: 0px;
    font-size: 1.4em;
  }

  .link-icon-btn {
    position: relative;
    overflow: visible;
    margin-top: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 1em;
  }

  .icon-2 {
    position: absolute;
    left: 0%;
    top: auto;
    right: 0%;
    bottom: -17%;
    width: 0.056vw;
    height: 1.714em;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    color: #fff;
  }

  .icon-2.mob {
    color: #000;
  }

  .section-3 {
    padding-top: 5em;
  }

  .laep-features {
    margin-top: 5em;
    margin-bottom: 5em;
    padding: 2em 8.5vw;
  }

  .heading-11 {
    font-size: 0.7em;
  }

  .text-block-5 {
    font-size: 0.7em;
  }

  .image-11 {
    width: 55vw;
    height: 40em;
    margin-top: 37.44285714em;
    margin-left: -46.2vw;
    padding-top: 0px;
    padding-left: 0px;
  }

  .grid-9 {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
  }

  .categories {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto auto auto auto;
    grid-template-rows: auto auto auto auto auto;
  }

  .image-14 {
    overflow: visible;
    opacity: 0;
  }

  .image-15 {
    position: relative;
    left: auto;
    top: auto;
    right: 0%;
    bottom: 0%;
    width: 100vw;
    height: 50%;
  }

  .image-16 {
    width: 100%;
    height: 50%;
  }

  .faq-section {
    margin-top: 8em;
    margin-bottom: 0.6em;
    padding-top: 1em;
    padding-right: 28px;
    padding-left: 5.5vw;
  }

  .faq-heading {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 0.85em;
  }

  .faq-question-block {
    padding-top: 1em;
    padding-bottom: 1em;
    font-size: 0.7em;
  }

  .faq-arrow {
    left: 0%;
    top: 0.3em;
    width: 2.3vw;
  }

  .faq-question {
    margin-left: 3.38em;
    font-size: 0.7em;
  }

  .cds-section {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 5em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .cds-section-wrapper {
    width: 58vw;
    margin-top: 5em;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .cds-card {
    padding: 5em 0vw;
  }

  .cds-card-title {
    font-size: 0.8em;
  }

  .cds-card-para {
    font-size: 0.7em;
  }

  .cds-hero {
    height: 80vh;
  }

  .cds-title {
    font-size: 2.8em;
  }

  .outlined-btn {
    font-size: 1em;
  }

  .cds-section-1 {
    padding-top: 5em;
  }

  .dec-img {
    left: auto;
    top: 0%;
    right: 0%;
    bottom: auto;
    width: 100%;
    height: 100%;
    border-radius: 0px;
    -webkit-transform: skew(0deg, 0deg);
    -ms-transform: skew(0deg, 0deg);
    transform: skew(0deg, 0deg);
  }

  .platform-desc-section {
    padding-top: 2em;
  }

  .plt-grid {
    grid-row-gap: 1.4em;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .plt-para {
    font-size: 0.7em;
    font-weight: 700;
  }

  .plt-selector {
    font-size: 0.8em;
  }

  .cds-section-heading {
    font-size: 0.8em;
  }

  .text-block-8 {
    font-size: 1em;
  }

  .cds-section-list {
    font-size: 1em;
  }

  .cds-section-2 {
    margin-top: 5em;
  }

  .tabs {
    margin-top: 4em;
  }

  .tab-link {
    padding: 1em 16px;
  }

  .div-block-26 {
    position: relative;
    left: -1.72546296em;
    top: -34em;
  }

  .div-block-27 {
    position: relative;
    left: -41vw;
  }

  .about-dec {
    top: 0px;
    right: 0px;
  }

  .cs-title {
    font-size: 2.8em;
  }

  .cs-hero-text {
    font-size: 1.4em;
  }

  .cs-desc-section {
    padding-right: 6.4vw;
    padding-left: 6.4vw;
  }

  .cs-desc {
    margin-bottom: 2.41em;
    font-size: 0.85em;
  }

  .events-hero {
    overflow: visible;
    margin-top: -1px;
    margin-bottom: 232px;
    background-image: url('../images/Rectangle-402.png');
    background-position: 0px 0px;
    background-size: contain;
  }

  .hero-wrapper-events {
    margin-bottom: -40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .ev-hero-para {
    position: relative;
    left: 0%;
    top: auto;
    right: auto;
    bottom: 0%;
    display: inline-block;
    margin: -197px 0px 8.9em 9px;
    padding-right: 0px;
    direction: ltr;
    font-size: 1.8em;
    text-align: left;
    letter-spacing: 0.05px;
  }

  .ev-hero-title {
    margin-bottom: 3.1em;
    margin-left: 4px;
  }

  .event-aside {
    max-width: 100%;
    margin-bottom: 24.6em;
  }

  .rc-link-block {
    height: 50%;
    padding-top: 57%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .about-heading {
    margin-top: 211px;
    color: #4e4e4e;
    font-size: 2.2857142857142856em;
  }

  .paragraph-8 {
    position: relative;
    width: 56vw;
    margin-right: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
  }

  .paragraph-11 {
    position: relative;
    top: -42px;
    width: 55vw;
    font-size: 2.5em;
  }

  .teams-heading {
    overflow: visible;
    font-size: 2.5em;
  }

  .demo-banner-prm {
    margin-top: -12.4em;
  }

  .about-hero-dec {
    margin-top: 18em;
    padding-bottom: 2.8em;
  }

  .teams-section {
    background-size: 75%;
  }

  .div-block-36 {
    position: relative;
    top: -51px;
    width: 9vw;
  }

  .teams-hero-para-2 {
    position: relative;
    left: -421px;
    top: 8.5em;
    width: 250vw;
    margin-right: -43.85vw;
    margin-bottom: 4.8em;
    padding-right: 0px;
    font-size: 1.5em;
  }

  .teams-grid {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
  }

  .work-with-us-section {
    margin-top: 11.1em;
    background-position: 100% 0%;
    background-size: cover;
    background-repeat: repeat-x;
  }

  .wwi-heading {
    margin-top: -166px;
    padding-bottom: 0.7em;
    font-size: 3em;
    font-weight: 700;
  }

  .wwu-desc {
    width: 30em;
    margin-top: -39px;
    margin-left: 0px;
    font-size: 1.5em;
    font-weight: 600;
    text-align: center;
  }

  .text-block-10 {
    position: relative;
    left: -250px;
    top: 303px;
  }

  .image-20 {
    position: relative;
    left: -242px;
    top: 304px;
  }

  .gcloud-section {
    margin-top: 4.7em;
    margin-bottom: 5em;
  }

  .gcloud-img {
    width: 30vw;
  }

  .gcloud-caption {
    font-size: 2em;
  }

  .list {
    margin-bottom: 2em;
    padding-left: 12px;
  }

  .faq-answer {
    padding-left: 3.38em;
    font-size: 0.7em;
  }

  .menu-button {
    margin-top: 0.2em;
    color: #fff;
  }

  .menu-button.w--open {
    background-color: transparent;
  }

  .menu-button.black {
    color: #000;
  }

  .brand {
    display: block;
  }

  .brand-link {
    display: none;
  }

  .brand-link.mobile {
    display: block;
  }

  .menu-button-2.w--open {
    background-color: transparent;
  }

  .brand-2 {
    display: none;
  }

  .mobile-logo {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .hero-heading {
    padding: 60px 15px;
  }

  .granular-data.pad-img-right {
    margin-top: 30px;
    padding-right: 12px;
    padding-left: 32px;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }

  .footer {
    padding-right: 15px;
    padding-left: 15px;
  }

  .sign-up-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .footer-form-title {
    margin-right: 0px;
  }

  .footer-form-block-two {
    width: 100%;
  }

  .subscribe-input {
    width: 100%;
  }

  .footer-social-block-three {
    margin-top: 10px;
  }

  .footer-legal-block {
    margin-top: 15px;
    margin-left: -24px;
  }

  .menu-button {
    padding: 4px;
  }
}

@media screen and (max-width: 479px) {
  .body {
    font-size: 10px;
  }

  .navlogo.whitelogo {
    margin-left: 0px;
  }

  .footer-form-two {
    padding: 20px;
  }

  .sticky-bar-wrapper {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .cs-slider {
    height: 45em;
  }

  .div-block-9 {
    background-color: #fff;
  }

  .div-block-11 {
    position: absolute;
    left: -39%;
    height: 80%;
  }

  .image-4 {
    background-color: #fff;
  }

  .menu-button {
    padding: 4px;
  }

  .brand-link.mobile {
    margin-top: 0.3em;
    padding-left: 0px;
  }
}

#w-node-df9e40f8-3392-504a-294c-345675f998d3-46f3d9ac {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_5692cd70-b0e1-50fd-ed53-6e8cd592ad2b-46f3d9ac {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_74777dfc-df05-6c8c-978d-ff9805e9fbf8-46f3d9ac {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-c00af91c-16ea-247e-bc67-3b0d72cab8cf-bee9dca4 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-c00af91c-16ea-247e-bc67-3b0d72cab8d3-bee9dca4 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-c00af91c-16ea-247e-bc67-3b0d72cab8d7-bee9dca4 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_7d4db092-5d7b-5373-984d-0e07f855f5d5-bee9dca4 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_7d4db092-5d7b-5373-984d-0e07f855f5d9-bee9dca4 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_7d4db092-5d7b-5373-984d-0e07f855f5dd-bee9dca4 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_2a92809e-5037-ea7c-058b-d6ec9e85e3b6-bee9dca4 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_2a92809e-5037-ea7c-058b-d6ec9e85e3ba-bee9dca4 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_2a92809e-5037-ea7c-058b-d6ec9e85e3be-bee9dca4 {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_830d80bf-cd0d-3452-fff9-ac1bb760270d-b760270d {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_405f56b9-4d22-7353-725a-201be1593d9c-89d9dd52 {
  -ms-grid-column: span 3;
  grid-column-start: span 3;
  -ms-grid-column-span: 3;
  grid-column-end: span 3;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-be7bc9d3-547b-b086-26bb-2f267d6a501c-7d6a501c {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_5180f5c3-f043-3f72-449e-b477d1bb14f1-ef4eaeeb {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-cafd9326-509a-ec3d-1e98-255dae803763-ef4eaeeb {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-d8df4238-f9d5-b790-cc6e-631f1db057b4-ef4eaeeb {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_323bc60b-2067-2680-2a08-e9e2de1e7291-ef4eaeeb {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_183c9f3c-65d5-eef0-42be-e1007906d38d-ef4eaeeb {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_901dca73-f3d3-a803-0ebf-e497aa50ff88-ef4eaeeb {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-e42f6a1c-e8f3-956e-0eea-47112bbffd51-ef4eaeeb {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_95cc3c03-4a03-c4b1-60ce-f0c2ec9d682e-ef4eaeeb {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_0a449f93-c943-5417-f785-7dc7002c336b-ef4eaeeb {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-d7ea8d4e-6b4d-e9e6-3fd5-0d051e84755a-ef4eaeeb {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_188bbf33-679f-6279-eea7-0990e5611c5b-ef4eaeeb {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_5a1d55f1-03b5-6c01-9a71-38d62d77ac40-ef4eaeeb {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

